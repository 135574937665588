export const parseCurrency = (value: string | number): number => {
  return Number(parseFloat(value.toString()).toFixed(2))
}

export const currencyToString = (
  value: number,
  includeEmptyDecimals = false,
) => {
  const str = value.toFixed(2)
  if (str.endsWith('.00') && !includeEmptyDecimals) return value.toString()
  return str
}
