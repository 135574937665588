import dayjs from 'dayjs'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'

import {
  displayShipType,
  FullShipment,
  LegacyShipType,
  LoadDocument,
  PickupDetail,
  ShipmentLocation,
  StopDateType,
  StopType,
  Workflow,
} from '@fv/client-types'
import { LocationAccessorialDTO } from '@fv/models'

import { TypeNamesProvider } from './useTypeNames'

export function buildTrackStatusOptions(locations: ShipmentLocation[]) {
  const options = [{ text: 'Ready', value: '-1' }]

  locations.forEach(x => {
    const option = { text: `Stop ${x.sequence}`, value: x.sequence.toString() }
    if (x.sequence === 0) option.text = 'Picked up'
    if (x.sequence === locations.length - 1) option.text = 'Delivered'
    options.push(option)
  })

  return options
}

export function composeStopDetails({
  stopDate,
  stopDateType,
  stopType,
}: {
  stopDate?: string | Date | null
  stopDateType: StopDateType
  stopType: StopType
}) {
  const date = stopDate ? dayjs(stopDate).format('MMM D') : null
  let type = stopType === 'pickup' ? 'Pick up' : 'Deliver'

  if (stopType === 'both') type = 'Pick up and deliver'
  return `${type}${date ? ` ${stopDateType} ${date}` : ''}`
}

export function getTrackingNumLabel(workflow: Workflow) {
  return workflow === 'ltl' ? 'PRO#' : 'Tracking#'
}

export function getCurrentSequence(
  tracking?: {
    currentLocation?: { sequence?: number }
    currentStatus?: string
  },
  isLegacy?: boolean,
) {
  const { currentLocation, currentStatus } = tracking ?? {}

  if (typeof currentLocation?.sequence === 'number') {
    return currentLocation.sequence
  }

  // Legacy shipments may not have a sequence set
  if (isLegacy) {
    if (currentStatus === 'in-transit') return 0
    if (currentStatus === 'delivered') return 1
  }

  return -1
}

export function tenderInProgressOrComplete({
  pickup,
}: {
  pickup?: PickupDetail
}) {
  return (
    pickup?.status === 'ok' ||
    pickup?.status === 'pending' ||
    pickup?.status === 'requesting'
  )
}

export function totalShipmentWeight({
  equipment,
  items,
}: Pick<FullShipment, 'equipment' | 'items'>) {
  const itemWeight = sumBy(items, 'weight')
  const totalWeight = itemWeight > 0 ? itemWeight : (equipment.weight ?? 0)
  return round(totalWeight, 2)
}

export function getItemLabels(
  documents: LoadDocument[] | undefined,
  itemId: string,
) {
  return (documents ?? []).filter(
    d => d.itemId === itemId && d.type === 'label',
  )
}

export function displayLocShipType({
  shipType,
  accessorials,
}: {
  shipType?: LegacyShipType | ''
  accessorials?: LocationAccessorialDTO[]
}) {
  if (
    !shipType ||
    shipType === 'business dock' ||
    shipType === 'business no dock'
  ) {
    return null
  }
  const shipTypeDisplay = displayShipType(shipType)
  if (shipType === 'trade show') {
    const boothName = accessorials?.find(
      a => a.key === 'tradeshow',
    )?.tradeshowBooth
    if (boothName) {
      return `${shipTypeDisplay} (Booth ${boothName})`
    }
  }
  return shipTypeDisplay
}

export function getLocAccessorials(
  { accessorials }: Pick<ShipmentLocation, 'accessorials'>,
  provider: TypeNamesProvider,
) {
  const { accessorialName } = provider
  return accessorials
    ?.filter(a => a.key !== 'tradeshow')
    ?.map(a => {
      const name = accessorialName(a.key)
      const appointmentTime =
        a.key === 'schedule' ? buildAppointmentTime(a) : undefined

      return {
        appointmentTime,
        key: a.key,
        name,
      }
    })
}

export function buildAppointmentTime(
  { opensAt, closesAt }: { opensAt?: string; closesAt?: string },
  opts?: { outputFormat: string },
) {
  const outFmt = opts?.outputFormat ?? 'h:mm a'
  const closeTime = dayjs(closesAt, 'HH:mm').format(outFmt)
  const openTime = dayjs(opensAt, 'HH:mm').format(outFmt)
  if (opensAt && closesAt) return `${openTime} to ${closeTime}`
  if (opensAt && !closesAt) return `After ${openTime}`
  if (!opensAt && closesAt) return `Before ${closeTime}`
  return undefined
}
