import clsx from 'clsx'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FvLinkButton, Icon } from '@fv/client-components'
import { SlashSeparated } from '@/components/SlashSeparated'

import { type Opportunity } from '../../types/Opportunity'
import { useUpdateOppDetailsHelpers } from '../opp-details/mutations'
import { useEditConfirmationNumber } from './hooks/useEditConfirmationNumber'
import { LoadDetails } from './LoadDetails'
import { RefNumbers } from './RefNumbers'
import { RefNumDisplayToggle, RefNumForm } from './RefNumEditor'
import { StopList } from './StopList'

type ShipmentDetailsProps = {
  opportunity: Opportunity
  listClassName?: string
}
type EditNumTypes = 'conf' | 'track' | 'trailer' | 'seal'
export const ShipmentDetails = ({
  opportunity,
  listClassName,
}: ShipmentDetailsProps) => {
  const location = useLocation()

  const {
    bol,
    isArchived,
    isMultiTruck,
    loadId,
    locations,
    pickup,
    quoteRequestId,
    refNums,
    shipper,
    status,
  } = opportunity
  const editConfirmation = useEditConfirmationNumber(loadId)
  const { updateSealNumber, updateTrackingNumber, updateTrailerNumber } =
    useUpdateOppDetailsHelpers(opportunity)

  const [editingNum, setEditingNum] = useState<EditNumTypes>()
  const handleEditToggle = (type: EditNumTypes) => () =>
    setEditingNum(p => (p === type ? undefined : type))
  const canEditConfNum = status === 'confirmed' && !isArchived
  const confNum = pickup?.confirmationNumber ?? ''
  const isMultiStop = locations.length > 2
  const isViewingRelatedOpps = location.pathname.startsWith('/direct/')
  const refNumbers = refNums?.filter(n => n.value !== bol?.bolNumber)
  const showConfNum = Boolean(confNum) || canEditConfNum
  const trackingNumber = opportunity.track?.trackingNumber
  const trailerNum = opportunity.refNums?.find(
    r => r.type === 'trailer-num',
  )?.value
  const sealNum = opportunity.refNums?.find(r => r.type === 'seal-num')?.value

  let fromLabel = 'From'
  if (isMultiStop) fromLabel = 'Multi stop shipment from'
  if (isMultiTruck) fromLabel = 'Multi truck shipment from'

  return (
    <div
      className={clsx('shipment-item__information', {
        'shipment-item__information--multi-stop': isMultiStop,
      })}
    >
      <ul
        className={clsx('general-list general-list-fc-no-pad', listClassName)}
      >
        <li className="general-list__item flex-wrap leading-6 pr-10 relative">
          {/* mg mockup ticket 23686 - icon has to be a child for correct animation purposes */}
          {/* <Tooltip label="You have unread messages">
            <FvButton className="absolute top-1 right-2">
              <Icon icon="comment-alt-dots" className=" pulse-with-color" />
            </FvButton>
          </Tooltip> */}
          {/* end */}
          {(isMultiStop || isMultiTruck) && (
            <Icon
              icon={isMultiStop ? 'route-interstate' : 'layer-group'}
              className="fa-fw"
            />
          )}
          <SlashSeparated>
            {bol?.bolNumber && <span>BOL# {bol.bolNumber}</span>}

            {showConfNum && (
              <RefNumDisplayToggle
                label="Confirmation#"
                showEmpty
                readonly={status !== 'confirmed' || isArchived}
                value={confNum}
                onClick={handleEditToggle('conf')}
              />
            )}
            <RefNumDisplayToggle
              label="Tracking#"
              value={trackingNumber}
              onClick={handleEditToggle('track')}
            />
            <RefNumDisplayToggle
              label="Trailer#"
              value={trailerNum}
              onClick={handleEditToggle('trailer')}
            />
            <RefNumDisplayToggle
              label="Seal#"
              value={sealNum}
              onClick={handleEditToggle('seal')}
            />
            <span>
              {fromLabel}: {shipper.companyName}
            </span>
          </SlashSeparated>
          {status !== 'lost' && (
            <span>
              &nbsp;
              <RefNumbers refNums={refNumbers} />
            </span>
          )}
          {editingNum === 'conf' && (
            <RefNumForm
              initialValue={confNum}
              label="Update confirmation number"
              name="confirmationNum"
              mutation={editConfirmation}
              onToggle={() => setEditingNum(undefined)}
            />
          )}
          {editingNum === 'track' && (
            <RefNumForm
              initialValue={trackingNumber ?? ''}
              label="Update tracking number"
              name="tracking"
              mutation={updateTrackingNumber}
              onToggle={() => setEditingNum(undefined)}
            />
          )}

          {editingNum === 'trailer' && (
            <RefNumForm
              initialValue={trailerNum ?? ''}
              label="Update trailer number"
              name="trailer"
              mutation={updateTrailerNumber}
              onToggle={() => setEditingNum(undefined)}
            />
          )}

          {editingNum === 'seal' && (
            <RefNumForm
              initialValue={sealNum ?? ''}
              label="Update seal number"
              name="seal"
              mutation={updateSealNumber}
              onToggle={() => setEditingNum(undefined)}
            />
          )}
        </li>

        <StopList
          bolNum={bol?.bolNumber}
          locations={locations}
          showAddress={status === 'awarded'}
        />

        <LoadDetails load={opportunity} />
        {isMultiTruck && !isViewingRelatedOpps && (
          <li className="general-list__item">
            <FvLinkButton
              icon="eye"
              className="standard-link"
              iconClass="fa-fw"
              to={`/direct/${quoteRequestId}`}
            >
              View related opportunities
            </FvLinkButton>
          </li>
        )}
      </ul>
    </div>
  )
}
